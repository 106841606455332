import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import lockOutline from '@iconify/icons-eva/lock-outline';
import settingsOutline from '@iconify/icons-eva/settings-outline';
import logOutOutline from '@iconify/icons-eva/log-out-outline';
import menuOutline from '@iconify/icons-eva/menu-outline';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Tooltip
} from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MIconButton } from '../../components/@material-extend';
import ChangePasswordForm from '../../components/authentication/change-password/ChangePasswordForm';
import GeneralSettingsForm from 'components/_dashboard/agency/GeneralSettingsForm';
import { resetState as resetAgencyState } from 'redux/slices/agency';
import { getAllNewsList } from 'redux/slices/teacher-news';
// constants
import { Roles } from '../../constants/roles';
import GeneralDirectorSettingForm from 'components/_dashboard/director/GeneralDirectorSettingForm';
import { getDirectorSetting } from 'redux/slices/director-dashboard';
// ----------------------------------------------------------------------

const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 56;
const APPBAR_DESKTOP = 56;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  borderBottom: '1px solid #EAEAEA',
  backgroundColor: '#fff',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isCollapse } = useCollapseDrawer();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { newsList } = useSelector((state: RootState) => state.teacherNews);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const [openDirectorSettings, setOpenDirectorSettings] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      navigate('/');
    } catch (error: any) {
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const handleChangePasswordClosed = () => {
    setOpenChangePassword(false);
  };

  const handleSettingsClosed = () => {
    setOpenSettings(false);
    dispatch(resetAgencyState());
  };
  const handleDierctorSettingsClosed = () => {
    setOpenDirectorSettings(false);
  };

  useEffect(() => {
    if (user?.roleId === Roles.STUDENT) {
      dispatch(getAllNewsList());
    }
  }, [dispatch, Roles, user]);

  return (
    <>
      <RootStyle
        sx={[
          {
            ...(isCollapse && {
              width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
            })
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              zIndex: 1202
            }
          })
        ]}
      >
        <ToolbarStyle>
          <Box
            sx={[
              {
                px: 2.5,
                my: 1.5,
                mr: 13
              },
              (theme) => ({
                [theme.breakpoints.between('md', 'lg')]: {
                  mr: 7
                },
                [theme.breakpoints.down('md')]: {
                  mr: 'auto'
                }
              })
            ]}
          >
            <NavLink
              to={'/'}
              style={{
                color: 'text.secondary',
                textTransform: 'uppercase',
                fontSize: '14px',
                textDecoration: 'none',
                outline: 'none'
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                noWrap
              >
                {user?.agencyName ? user.agencyName : user?.fullName}
              </Typography>
            </NavLink>
          </Box>

          <Box
            sx={[
              { flexGrow: 1 },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              })
            ]}
          >
            {user?.roleId === Roles.AGENCY && (
              <>
                <NavLink to={'/dashboard/provider'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/provider' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                      noWrap
                    >
                      PROVIDERS
                    </Typography>
                  </Box>
                </NavLink>

                {user?.billingModule === true && (
                  <NavLink to={'/dashboard/billing/list'}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        padding: '23px 0 19px 0',
                        mx: '20px',
                        borderBottomWidth: '4px',
                        borderBottomStyle: 'solid',
                        borderBottomColor:
                          location.pathname === '/dashboard/billing/list'
                            ? '#438FFE'
                            : 'transparent'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase',
                          fontSize: '14px'
                        }}
                        noWrap
                      >
                        BILLING
                      </Typography>
                    </Box>
                  </NavLink>
                )}

                {user?.newsModule === true && (
                  <NavLink to={'/dashboard/news/list'}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        padding: '23px 0 19px 0',
                        mx: '20px',
                        borderBottomWidth: '4px',
                        borderBottomStyle: 'solid',
                        borderBottomColor:
                          location.pathname === '/dashboard/news/list' ? '#438FFE' : 'transparent'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase',
                          fontSize: '14px'
                        }}
                        noWrap
                      >
                        NEWS
                      </Typography>
                    </Box>
                  </NavLink>
                )}

                {user?.billingModule === true && (
                  <>
                    <NavLink to={'/dashboard/position/list'}>
                      <Box
                        sx={{
                          display: 'inline-block',
                          padding: '23px 0 19px 0',
                          mx: '20px',
                          borderBottomWidth: '4px',
                          borderBottomStyle: 'solid',
                          borderBottomColor:
                            location.pathname === '/dashboard/position/list'
                              ? '#438FFE'
                              : 'transparent'
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                            textTransform: 'uppercase',
                            fontSize: '14px'
                          }}
                          noWrap
                        >
                          POSITIONS
                        </Typography>
                      </Box>
                    </NavLink>

                    <NavLink to={'/dashboard/service-status/list'}>
                      <Box
                        sx={{
                          display: 'inline-block',
                          padding: '23px 0 19px 0',
                          mx: '20px',
                          borderBottomWidth: '4px',
                          borderBottomStyle: 'solid',
                          borderBottomColor:
                            location.pathname === '/dashboard/service-status/list'
                              ? '#438FFE'
                              : 'transparent'
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                            textTransform: 'uppercase',
                            fontSize: '14px'
                          }}
                          noWrap
                        >
                          SERVICE STATUSES
                        </Typography>
                      </Box>
                    </NavLink>

                    <NavLink to={'/dashboard/calendar'}>
                      <Box
                        sx={{
                          display: 'inline-block',
                          padding: '23px 0 19px 0',
                          mx: '20px',
                          borderBottomWidth: '4px',
                          borderBottomStyle: 'solid',
                          borderBottomColor:
                            location.pathname === '/dashboard/calendar' ? '#438FFE' : 'transparent'
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                            textTransform: 'uppercase',
                            fontSize: '14px'
                          }}
                          noWrap
                        >
                          CALENDAR
                        </Typography>
                      </Box>
                    </NavLink>
                  </>
                )}

                {user?.bonusModule === true && (
                  <NavLink to={'/dashboard/bonus'}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        padding: '23px 0 19px 0',
                        mx: '20px',
                        borderBottomWidth: '4px',
                        borderBottomStyle: 'solid',
                        borderBottomColor:
                          location.pathname === '/dashboard/bonus' ? '#438FFE' : 'transparent'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase',
                          fontSize: '14px'
                        }}
                        noWrap
                      >
                        BONUS
                      </Typography>
                    </Box>
                  </NavLink>
                )}
              </>
            )}

            {user?.roleId === Roles.ADMIN && (
              <>
                <NavLink to={'/dashboard/agency/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/agency/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      AGENCIES
                    </Typography>
                  </Box>
                </NavLink>

                <NavLink to={'/dashboard/director/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/director/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      DIRECTORS
                    </Typography>
                  </Box>
                </NavLink>
              </>
            )}

            {user?.roleId === Roles.DIRECTOR && (
              <>
                <NavLink to={'/dashboard/class/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/class/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      CLASSES
                    </Typography>
                  </Box>
                </NavLink>

                <NavLink to={'/dashboard/teacher/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/teacher/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      TEACHERS
                    </Typography>
                  </Box>
                </NavLink>

                <NavLink to={'/dashboard/director/news/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/director/news/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      NEWS
                    </Typography>
                  </Box>
                </NavLink>
                <NavLink to={'/dashboard/homework/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/homework/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Home
                    </Typography>
                  </Box>
                </NavLink>
                {user?.billingModule === true && (
                  <NavLink to={'/dashboard/director/billing/list'}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        padding: '23px 0 19px 0',
                        mx: '20px',
                        borderBottomWidth: '4px',
                        borderBottomStyle: 'solid',
                        borderBottomColor:
                          location.pathname === '/dashboard/director/billing/list'
                            ? '#438FFE'
                            : 'transparent'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase',
                          fontSize: '14px'
                        }}
                        noWrap
                      >
                        BILLING
                      </Typography>
                    </Box>
                  </NavLink>
                )}
              </>
            )}

            {user?.roleId === Roles.TEACHER && (
              <>
                <NavLink to={'/dashboard/home/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/home/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Home
                    </Typography>
                  </Box>
                </NavLink>
                <NavLink to={'/dashboard/student/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/student/list' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Students
                    </Typography>
                  </Box>
                </NavLink>
                <NavLink to={'/dashboard/teacher/news/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/teacher/news/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Student News
                    </Typography>
                  </Box>
                </NavLink>
                <NavLink to={'/dashboard/user/news/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/user/news/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Faculty News
                    </Typography>
                  </Box>
                </NavLink>
                {/* <NavLink to={'/dashboard/teacher/homework/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/teacher/homework/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Homework
                    </Typography>
                  </Box>
                </NavLink> */}
                {/* <NavLink to={'/dashboard/teacher/homework/submission/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/teacher/homework/submission/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Homework Submission
                    </Typography>
                  </Box>
                </NavLink> */}
                {/* <NavLink to={'/dashboard/teacher/grade'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/teacher/grade' ? '#438FFE' : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Grade
                    </Typography>
                  </Box>
                </NavLink> */}
                {user?.billingModule === true && (
                  <NavLink to={'/dashboard/teacher/billing'}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        padding: '23px 0 19px 0',
                        mx: '20px',
                        borderBottomWidth: '4px',
                        borderBottomStyle: 'solid',
                        borderBottomColor:
                          location.pathname === '/dashboard/teacher/billing'
                            ? '#438FFE'
                            : 'transparent'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase',
                          fontSize: '14px'
                        }}
                        noWrap
                      >
                        Billing
                      </Typography>
                    </Box>
                  </NavLink>
                )}
              </>
            )}

            {user?.roleId === Roles.STUDENT && (
              <>
                {newsList.length > 0 && (
                  <NavLink to={'/dashboard/student/news/view'}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        padding: '23px 0 19px 0',
                        mx: '20px',
                        borderBottomWidth: '4px',
                        borderBottomStyle: 'solid',
                        borderBottomColor:
                          location.pathname === '/dashboard/student/news/view'
                            ? '#438FFE'
                            : 'transparent'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase',
                          fontSize: '14px'
                        }}
                        noWrap
                      >
                        NEWS
                      </Typography>
                    </Box>
                  </NavLink>
                )}
                <NavLink to={'/dashboard/student/grade/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/student/grade/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Grade
                    </Typography>
                  </Box>
                </NavLink>
                <NavLink to={'/dashboard/user/news/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/user/news/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      USER NEWS
                    </Typography>
                  </Box>
                </NavLink>
                <NavLink to={'/dashboard/student/homework/list'}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '23px 0 19px 0',
                      mx: '20px',
                      borderBottomWidth: '4px',
                      borderBottomStyle: 'solid',
                      borderBottomColor:
                        location.pathname === '/dashboard/student/homework/list'
                          ? '#438FFE'
                          : 'transparent'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'uppercase',
                        fontSize: '14px'
                      }}
                      noWrap
                    >
                      Homework
                    </Typography>
                  </Box>
                </NavLink>
              </>
            )}
          </Box>

          {user?.roleId === Roles.AGENCY && (
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Tooltip title="Settings">
                <IconButton
                  onClick={() => {
                    setOpenSettings(true);
                  }}
                >
                  <Icon icon={settingsOutline} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {user?.roleId === Roles.DIRECTOR && (
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Tooltip title="Settings">
                <IconButton
                  onClick={() => {
                    setOpenDirectorSettings(true);
                    dispatch(getDirectorSetting());
                  }}
                >
                  <Icon icon={settingsOutline} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Tooltip title="Change Password">
              <IconButton
                onClick={() => {
                  setOpenChangePassword(true);
                }}
              >
                <Icon icon={lockOutline} width={20} height={20} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={[
              { p: 2, pt: 1.5, cursor: 'pointer', display: 'flex' },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              })
            ]}
            onClick={handleLogout}
          >
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', marginTop: '9px', marginRight: '9px' }}
              noWrap
            >
              Logout
            </Typography>
            <MIconButton>
              <Icon icon={logOutOutline} />
            </MIconButton>
          </Box>

          <Box
            sx={[
              { p: 2, pt: 1.5, cursor: 'pointer', display: 'none' },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  display: 'block'
                }
              })
            ]}
            onClick={handleMenuOpen}
          >
            <MIconButton>
              <Icon icon={menuOutline} />
            </MIconButton>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {user?.roleId === Roles.AGENCY && (
              <div>
                <MenuItem
                  onClick={() => {
                    navigate('/dashboard/provider');
                  }}
                >
                  PROVIDERS
                </MenuItem>
                {user?.billingModule === true && (
                  <MenuItem
                    onClick={() => {
                      navigate('/dashboard/billing/list');
                    }}
                  >
                    BILLING
                  </MenuItem>
                )}
                {user?.newsModule === true && (
                  <MenuItem
                    onClick={() => {
                      navigate('/dashboard/news/list');
                    }}
                  >
                    NEWS
                  </MenuItem>
                )}
                {user?.billingModule === true && (
                  <div>
                    <MenuItem
                      onClick={() => {
                        navigate('/dashboard/position/list');
                      }}
                    >
                      POSITIONS
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/dashboard/service-status/list');
                      }}
                    >
                      SERVICE STATUSES
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/dashboard/calendar');
                      }}
                    >
                      CALENDAR
                    </MenuItem>
                  </div>
                )}
                {user?.bonusModule === true && (
                  <MenuItem
                    onClick={() => {
                      navigate('/dashboard/bonus');
                    }}
                  >
                    BONUS
                  </MenuItem>
                )}
              </div>
            )}

            {user?.roleId === Roles.ADMIN && (
              <div>
                <MenuItem
                  onClick={() => {
                    navigate('/dashboard/agency/list');
                  }}
                >
                  AGENCIES
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/dashboard/director/list');
                  }}
                >
                  DIRECTORS
                </MenuItem>
              </div>
            )}

            {user?.roleId === Roles.DIRECTOR && (
              <div>
                <MenuItem
                  onClick={() => {
                    navigate('/dashboard/class/list');
                  }}
                >
                  CLASSES
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/dashboard/teacher/list');
                  }}
                >
                  TEACHERS
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/dashboard/director/news/list');
                  }}
                >
                  NEWS
                </MenuItem>
                {user?.billingModule === true && (
                  <MenuItem
                    onClick={() => {
                      navigate('/dashboard/director/billing/list');
                    }}
                  >
                    BILLING
                  </MenuItem>
                )}
              </div>
            )}
            <Divider />

            <MenuItem onClick={handleLogout}>
              <ListItemIcon sx={{ mr: 0 }}>
                <Icon icon={logOutOutline} />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </ToolbarStyle>
      </RootStyle>
      {openChangePassword && (
        <ChangePasswordForm openForm={openChangePassword} formClosed={handleChangePasswordClosed} />
      )}
      {openSettings && (
        <GeneralSettingsForm openForm={openSettings} formClosed={handleSettingsClosed} />
      )}
      {openDirectorSettings && (
        <GeneralDirectorSettingForm
          openForm={openDirectorSettings}
          formClosed={handleDierctorSettingsClosed}
        />
      )}
    </>
  );
}
